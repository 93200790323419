<template>
  <div class="order-done  m-scroll"    @scroll="handleScroll($event)">
    <HeaderBar title="订单物流" class="header-bg">
       <!-- <span slot="left" @click="editCart" class="editCart" v-if="cart.length > 0">
                {{isEdit ? '完成' : '编辑'}}
        </span> -->
    </HeaderBar>
    <div class="wrap">
      <div class="wrap-scroll" >
        <!-- 支付状态显示 -->
        <div class="order-doneBox" >
          <div class="payDone" v-show="status == 1">
            <img src="@/assets/pay/payok.png" alt="pay">
            <p >确认送达</p>
            <router-link :to="{path:'/zh'}"><div class="conPay">返回首页</div></router-link>
          </div>
          <div class="payDone" v-show="status == 2">
            <img src="@/assets/pay/payerr.png" alt="pay">
            <p>请求失败</p>
            <router-link :to="{path:'/zh'}"><div class="conPay">返回首页</div></router-link>
          </div>
          <div class="payDone" v-show="status == 3">
            <img src="@/assets/pay/payerr.png" alt="pay">
            <p v-show="status == 3">无此订单号</p>
            <router-link :to="{path:'/zh'}"><div class="conPay">返回首页</div></router-link>
          </div>

        </div>
        
      </div>
      <!-- 为您推荐 -->
      <m-divider title="为您推荐" class="m-cat-devider" v-if="!isEdit"></m-divider>
      <!-- 为你推荐 -->
      <goods-columns @cartClick="handleCart" :data="remGoodsList" class="m-cat-padding" v-if="!isEdit"></goods-columns>
      <p class="search-no" v-if='noMore'>暂无更多商品</p>  
      <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw" v-if="!isEdit"></van-loading>
      <!-- 选择SKU 加入购物车 -->
    </div>
    <m-sku :title="skuTitle" :show="skuModal" @close="val => skuModal=val" @success="handleCartSuccess" :goodsId.sync='goodsId'
    :barcode.sync="goodsBarCode" :num.sync="goodsNum" @edit="changeSku"></m-sku>
    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>
  </div>
</template>

<script>
import GoodsColumns from '@/components/zh/goods-columns.vue'
import HeaderBar from '@/components/zh/m-header.vue'
import MSku         from '@/components/zh/m-sku.vue'
import MDivider     from '@/components/zh/m-divider.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import { getCartRemGoods, getCart,  updateCart } from '@/api/zh/cart.js'
export default {
  name: "pay",
    components: {  GoodsColumns, HeaderBar, MSku, MDivider, MAnimate},
  data(){
    return {
      status:Number,
      remGoodsList: [],
      skuTitle    : '加入购物车',
      skuModal    : false,    // 控制显示 选择sku加入购物车面板
      goodsId     : '',
      goodsBarCode: '',
      goodsNum    : 1,
      noMore      : false,
      isEdit      : false,
      start       : false,      // 是否开启加入购物车动画
      start_dom   : null,   // 开启动画的起点DOM 
      page        : 1,
      loading     : false,
    }
  },
  methods:{
    handleScroll(e){
        if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
            if(!this.islock && !this.noMore){
                e.preventDefault()
                e.stopPropagation()
                this.loading = true
                this.page++
                this.getCartRemGoodsHandle()
            }
        }
    },
    getCartRemGoodsHandle() {
        // 获取推荐的商品
        this.islock   = true
        getCartRemGoods({page: this.page}).then(res => {
            this.loading  = false
            this.islock   = false
            if(res) {
                this.remGoodsList = this.remGoodsList.concat(res.data.data)
            }else{
                this.noMore = true
            }
        })
    },
    handleCart(goods, e){
        // 商品列表购物车点击事件
        this.skuTitle    = '加入购物车'
        this.goodsId     = goods
        this.skuModal    = true
        this.start_dom   = e 
        this.startAnimat = true
        this.isAddCart   = true
        this.getCartHandle();
    },
    handleCartEnd(){
        // 加入购物车动画结束
        this.start = false
    },
    handleCartSuccess(){
        // sku 弹窗加入购物车成功回调
        this.skuModal = false
        if(this.startAnimat) {
            let timer = setTimeout( () => {
                this.start = true

                // 重新获取购物车
                if(this.isAddCart) {
                    this.getCartHandle()
                }
                clearTimeout(timer)
            },300)
        }        
    },
    changeSku(sku) {
        updateCart(this.cartId, {buy_number: sku.num, barcode: sku.barcode}).then(res => {
            if(res) {
                this.$notify({ type: 'success', message: res.msg, })
                this.getCartHandle()
                this.skuModal = false
            }else{
                this.$notify({ type: 'warning', message: res.msg, })
            }
        })
    },
    getCartHandle() {
        // 获取购物车数据  {city: this.address ? this.address.split(' ').join('') : null}
        this.$store.commit('load')
        getCart({city: this.address}).then(res => {
            if(res.code == 20000) {
                this.cart = res.data
                this.allTotal = 0
                this.cart.forEach( (el)  => {
                    el.goods_list.forEach( ele => {
                        this.$set(ele,'select',false)
                    })
                    this.select.push({storeId: el.id, select: false, goods: el.goods_list, selectPrice: 0})
                })
                this.$forceUpdate()
            } else {
                this.allTotal = 0
            }
        }).catch(() => {
            this.cart = []
        }).finally(() => {
            this.$store.commit('unload')
        })
    },
    
  },
  mounted() {
    this.$nextTick(() => {
      this.getCartRemGoodsHandle()
    })
  },
  created() {
    this.status = this.$router.currentRoute.query.status;
  }
  
  
};
</script>
<style lang="less">
.order-done{
  width: 100%;height:100%; overflow-y: scroll;
  .vux-header{background: #f2f2f2 !important;border-bottom: 1px solid #e6e6e6;
    .vux-header-title{color:#141414 !important;font-size: 1.0625rem !important;letter-spacing: .05625rem}
    .vux-header-left .left-arrow:before{border: 1px solid #909090 !important;border-width: 1px 0 0 1px !important;}
  }
}
.order-done .commend .vux-x-icon {
  fill: #F70968;
  vertical-align: middle;
}
.order-done .nav .vux-x-icon {
  fill: #999999;
}
.order-done .vux-header .vux-header-title,
.order-done .cart .vux-header .vux-header-back,
.order-done .cart .vux-header .vux-header-right {
  color: #000;
}
.order-done .vux-header .vux-header-left .left-arrow:before {
  border: 1px solid #000;
  border-width: 1px 0 0 1px;
}
.order-done .vux-header .vux-header-left .vux-header-back {
  color: #000;
}

.order-doneBox{padding:50px 0 0}
.payDone,.notPay{width:100%;height:176px;border-bottom:16px solid #f5f5f5;background-color: #fff; box-sizing: content-box;
  img{margin:0 auto 10px auto;width:80px;height:80px;display: block}
  p{font-size: 16px;font-weight: bold}
}
.payDone p{text-align: center}
.payDone p:nth-child(1){color:#8bc34a;font-size: 20.8px;font-weight: bold;line-height: 80px}
.notPay p{text-align: center}
.notPay p:nth-child(1){color:#444;line-height: 60px;font-size: 20.8px;font-weight: bold;}
.conPay{margin: 14px auto 25px;width:160px;height:35px;text-align: center;line-height: 35px;font-size: 13px;color:#141414;background-color: #f7f7f7;
border-radius: 4px}


.order-done .wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0 auto 20px;
}

.m-cat-devider{
    margin:15px 0;
    font-size:18px;
    font-weight:500;
    color:rgba(15,30,51,1);
}

// 为你推荐
.remGoods{
  .weui-cell_access .weui-cell__ft:after {width: 8px;height: 8px;}
  .new-goods-box {padding-top:5px;overflow: hidden;width: 100%;background: #f7f7f7;}
  .new-goods-item {width: 45vw;font-size: 13px;display:block;background: #fff;float: left;margin:0px 0 10px 3.3%;border-radius: 10px;overflow: hidden;
  box-shadow: 0px 4px 4px 0px #f3f3f3;}
  .new-goods-item>a>img {width: 45vw;height:45vw;object-fit: cover}
  .new-goods-item>a>p{padding:0 10px}
  .new-goods-item>a>p:nth-of-type(1) {overflow:hidden;text-overflow:clip;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;line-height: 22px;height:44px;
  box-sizing: border-box;}
  .new-goods-item>a>p:nth-of-type(2) {font-size: 12px;margin-top: 10px;box-sizing: border-box;}
  .liner{width:50px;height:1px;background-color: #ddd;display: inline-block;vertical-align: middle}
  .box_tip{text-align: center;line-height: 40px;font-size: 17px;background: #f7f7f7;
      img{width:36px;height:28.5px;vertical-align: -6px;line-height: 40px;}
  }
  .boxPrice{padding:0 10px;line-height: 40px;font-size: 15px}
  .weui-cells:before{border:0;height:0}
}
.search-no {line-height:80px;text-align:center;color:#888;font-size:14px}


</style>


